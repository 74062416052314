.try-out {
  height: 100%;
  padding-top: 0.8rem;
}

.try-out .title {
  text-align: center;
  color: #0a62eb;
  font-size: 0.533333rem;
}

.try-out .container {
  width: 8rem;
  margin: 0 auto;
  padding-top: 0.533333rem;
}

.try-out .container .form-item {
  margin: 0.266667rem 0 0.266667rem 0;
}

.try-out .container .form-item input,
.try-out .container .form-item textarea {
  padding: 0 0.4rem;
  width: 7.2rem;
  background: #f7f7f7;
  border: none;
  font-size: 0.4rem;
  border-radius: 0.133333rem;
}

.try-out .container .form-item input {
  height: 1.066667rem;
  line-height: 1.066667rem;
}

.try-out .container .form-item textarea {
  height: 2.133333rem;
  padding: 0.4rem;
  resize: none;
}

.try-out .container .form-item .error {
  color: red;
  height: 0.533333rem;
  line-height: 0.533333rem;
  margin-top: 0.133333rem;
}

.try-out .container .submit {
  margin-top: 0.8rem;
  background: #035deb;
  border-radius: 0.266667rem;
  color: #ffffff;
  height: 1.333333rem;
  line-height: 1.333333rem;
  text-align: center;
  font-size: 0.426667rem;
  cursor: pointer;
}

.try-out .message {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  justify-content: center;
  align-items: center;
}

.try-out .message div {
  height: 1.6rem;
  padding: 0 0.8rem;
  line-height: 1.6rem;
  font-size: 0.533333rem;
  background: #ffffff;
  color: #67c23a;
}